import throttle from 'lodash/throttle';
import { BaseElement } from '../../01-base/BaseElement';

class StickyElement extends BaseElement {
    hooks() {
        return {
            connected: () => {
                this._clone = document.createElement('div');
                this._clone.classList.add('sticky-element-clone');
                this._clone.innerHTML = this.innerHTML;
                //append node right after self
                this.insertAdjacentHTML('afterend', this._clone.outerHTML);

                this._lastPageYOffset = 0;
                //initial evaluation
                this.evaluateOffset();
                this.evaluateStickyness();

                this.render();
            },
            disconnected: () => {
                // cleanup after disconnection
                this.parentNode.removeChild(this._clone);
            },
        };
    }

    properties() {
        return {
            position: 'top',
            stickyWithin: false,
            throttle: 50,
            animated: false,
            disabled: false,
            stickyFrom: '(min-width: 0px)',
        };
    }

    evaluateStickyness() {
        if (this._isDisabled || this.disabled === true) {
            //disabled by media query
            this.classList.remove('fix-element');
            return false;
        }
        const scrollDown = this._lastPageYOffset < window.pageYOffset;
        let windowReferencePoint = window.pageYOffset;

        if (this.position !== 'top') {
            windowReferencePoint += window.innerHeight;
        }
        const referenceTop = this.position === 'top';
        if (
            (!referenceTop &&
                windowReferencePoint < this._elementReferencePoint &&
                (this.stickyWithin === false ||
                    this._elementReferencePoint - this.stickyWithin < windowReferencePoint)) ||
            (referenceTop &&
                windowReferencePoint > this._elementReferencePoint &&
                (this.stickyWithin === false || this._elementReferencePoint + this.stickyWithin > windowReferencePoint))
        ) {
            // this.classList.remove('animate-out');
            if (this.animated && !this.classList.contains('fix-element')) {
                this.classList.add('animate-in');
            }
            this.classList.add('fix-element');
            // this.classList.add('animate-in');
        } else {
            if (
                this.animated &&
                this.classList.contains('fix-element') &&
                ((!referenceTop && !scrollDown) || (referenceTop && scrollDown))
            ) {
                this.classList.add('animate-out');
            } else {
                this.classList.remove('fix-element');
            }
        }

        this._lastPageYOffset = window.pageYOffset;
    }

    evaluateOffset() {
        this._isDisabled = this.disabled || !window.matchMedia(this.stickyFrom).matches;

        if (this.position === 'bottom') {
            this.classList.add('bottom');
        }

        const { top } = this.getBoundingClientRect();
        this._elementReferencePoint = top + window.pageYOffset;
        if (this.position !== 'top') {
            this._elementReferencePoint += this.offsetHeight;
        }
    }

    events() {
        return {
            window: {
                scroll: throttle(this.evaluateStickyness.bind(this), this.throttle),
                resize: this.evaluateOffset,
            },
            this: {
                animationend: () => {
                    if (this.classList.contains('animate-out')) {
                        this.classList.remove('fix-element');
                        this.classList.remove('animate-out');
                    }
                    if (this.classList.contains('animate-in')) {
                        this.classList.remove('animate-in');
                    }
                },
            },
        };
    }
}

customElements.define('sticky-element', StickyElement);
